<template>
  <div class="fill-organization-info">
    <div class="form-content">
      <div class="form-group">
        <div class="input-wrap">
          <div class="title must">机构全称</div>
          <div class="input-inner">
            <input
              v-model="organizeFullName"
              type="text"
              placeholder="请输入机构全称"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">机构代码</div>
          <div class="input-inner">
            <input
              v-model="organizeCode"
              type="text"
              placeholder="请输入机构统一社会信用代码"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrap">
          <div class="title must">管理员姓名</div>
          <div class="input-inner">
            <input
              v-model="adminFullName"
              type="text"
              placeholder="请输入管理员姓名"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">管理员联系电话与邮箱</div>
          <div class="input-inner">
            <input
              v-model="adminContact"
              type="text"
              placeholder="请输入管理员联系方式"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrap">
          <div class="title must load-file">
            上传机构公函(需盖章)
            <span
              ><a
                href="https://fafs.antiplagiarize.com/group1/M00/00/01/L2rchmIKBpmAVq2TAAA1fm95aL498.docx"
                ><img
                  :src="require('assets/images/download-blue.png')"
                  alt=""
                />公函样本下载</a
              ></span
            >
          </div>
          <div class="input-inner is-upload">
            <input
              type="file"
              name="officialLetter"
              ref="officialLetter"
              @change="changeFile(1)"
            />
            <span class="up-btn" @click="uploadLetter">
              <span v-show="!letterFile">点击上传盖章公函照片</span>
              <span v-show="letterFile" @click.stop="imgPreview(uploadedLetter)"
                >已上传，点击查看</span
              >
            </span>
            <i
              v-show="letterFile"
              class="el-icon-close clear"
              @click="clearLetter"
            ></i>
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">上传机构证照</div>
          <div class="input-inner is-upload">
            <input
              type="file"
              name="organizeIDPhoto"
              ref="organizeIDPhoto"
              @change="changeFile(2)"
            />
            <span class="up-btn" @click="uploadIDPhoto">
              <span v-show="!idPhotoFile">点击上传机构证照</span>
              <span
                v-show="idPhotoFile"
                @click.stop="imgPreview(uploadedIDPhoto)"
                >已上传，点击查看</span
              >
            </span>
            <i
              v-show="idPhotoFile"
              class="el-icon-close clear"
              @click="clearIDPhoto"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-btns">
        <button class="submit" @click="submit">提交</button>
        <button class="cancel" @click="cancel">取消</button>
      </div>
      <el-image
        ref="preview"
        style="width: 100px; height: 100px"
        :src="previewList[0]"
        :preview-src-list="previewList"
      >
      </el-image>
    </div>
  </div>
</template>

<script>
import { upload } from "api/common";
import { addRealName } from "api/copyrightService";
export default {
  name: "FillOrganizationInfo",
  data() {
    return {
      //机构全称
      organizeFullName: "",
      //机构代码
      organizeCode: "",
      //管理员姓名
      adminFullName: "",
      //管理员联系方式
      adminContact: "",
      //选择的文件类型 1-公函 2-证照
      fileType: 0,
      //公函文件
      letterFile: null,
      //证照文件
      idPhotoFile: null,
      //上传公函的文件值
      letterFileValue: "",
      //上传证照选择的文件值
      idPhotoFileValue: "",
      //上传默认地址
      uploadUrl: "https://fafs.antiplagiarize.com/",
      //已上传的公函完整地址
      uploadedLetter: "",
      //已上传的证照完整地址
      uploadedIDPhoto: "",
      //图片预览列表
      previewList: [],
    };
  },
  methods: {
    imgPreview(url) {
      this.previewList.pop();
      this.previewList.push(url);
      this.$refs["preview"].clickHandler();
    },
    //点击上传公函
    uploadLetter() {
      if (!this.letterFile) {
        this.$refs["officialLetter"].click();
        this.fileType = 1;
      }
    },
    //点击上传证照
    uploadIDPhoto() {
      if (!this.idPhotoFile) {
        this.$refs["organizeIDPhoto"].click();
        this.fileType = 2;
      }
    },
    //选择文件
    changeFile() {
      let file;
      if (this.fileType == 1) {
        file = this.$refs["officialLetter"].files[0];
        if (file && file.type.indexOf("image") == -1) {
          return this.$message({
            type: "warning",
            message: "请上传图片",
          });
        } else {
          this.uploadFile(file, (data) => {
            this.letterFile = file;
            this.letterFileValue = this.$refs["officialLetter"].value;
            this.$refs["officialLetter"].value = "";
            this.uploadedLetter = this.uploadUrl + data;
          });
        }
      } else {
        file = this.$refs["organizeIDPhoto"].files[0];
        if (file && file.type.indexOf("image") == -1) {
          return this.$message({
            type: "warning",
            message: "请上传图片",
          });
        } else {
          this.uploadFile(file, (data) => {
            this.idPhotoFile = file;
            this.idPhotoFileValue = this.$refs["organizeIDPhoto"].value;
            this.$refs["organizeIDPhoto"].value = "";
            this.uploadedIDPhoto = this.uploadUrl + data;
          });
        }
      }
    },
    //上传图片
    uploadFile(file, afterUpload) {
      let form = new FormData();
      form.append("file", file);
      upload(form).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "上传成功!",
          });
          afterUpload(res.data);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //机构认证
    authentication(successful) {
      const params = {
        realnameType: 2,
        idNumber: this.organizeCode,
        cardName: this.organizeFullName,
        adminName: this.adminFullName,
        adminMobile: this.adminContact,
        annexUrl: this.uploadedLetter,
        correspondence: this.uploadedIDPhoto,
      };
      addRealName(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
          successful();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //检查必填项
    checkRequired() {
      if (
        !this.organizeFullName ||
        !this.organizeCode ||
        !this.adminFullName ||
        !this.adminContact
      ) {
        this.$message({
          type: "error",
          message: "请先将信息填写完整!",
        });
        return false;
      }
      if (!this.letterFile) {
        this.$message({
          type: "error",
          message: "请上传机构公函!",
        });
        return false;
      }
      if (!this.idPhotoFile) {
        this.$message({
          type: "error",
          message: "请上传机构证照!",
        });
        return false;
      }
      return true;
    },
    //提交
    submit() {
      if (!this.checkRequired()) return;
      this.authentication(() => {
        this.$router.push({
          name: "OrganizationAlreadyRealName",
          params: {
            realName: this.organizeFullName,
          },
        });
        this.$store.commit("CHECK_REAL_NAME");
      });
    },
    //取消
    cancel() {
      this.$router.go(-1);
    },
    clearLetter() {
      this.letterFile = null;
      this.letterFileValue = "";
      this.uploadedLetter = "";
    },
    clearIDPhoto() {
      this.idPhotoFile = null;
      this.idPhotoFileValue = "";
      this.uploadedIDPhoto = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-image {
  display: none;
}
.fill-organization-info {
  .form-content {
    padding: 0 40px;
    .form-group {
      @include flex-start;
      margin-top: 45px;
      &:first-child {
        margin-top: 14px;
      }
      .input-wrap {
        @include flex-center(column);
        justify-content: normal;
        align-items: flex-start;
        width: 354px;
        .title {
          position: relative;
          padding-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          &.must::before {
            content: "*";
            position: absolute;
            top: 3px;
            left: 2px;
            font-size: 14px;
            color: #eb441e;
          }
          &.load-file {
            @include flex-between;
            width: 346px;
            > span a {
              @include flex-start;
              @include noselect;
              font-size: 12px;
              font-weight: 500;
              color: #ff6900;
              img {
                width: 16px;
                height: 14px;
                margin-right: 5px;
              }
              cursor: pointer;
            }
          }
        }
        .input-inner {
          position: relative;
          width: 100%;
          margin-top: 14px;
          input {
            width: 100%;
            height: 50px;
            padding: 10px 15px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            border: 1px solid #eceff1;
            border-radius: 5px;
            box-sizing: border-box;
            transition: border-color 0.3s;
            &:focus {
              border-color: #ff6900;
            }
          }
          &.is-upload {
            @include flex-start;
            input {
              flex: 1;
              &::-webkit-file-upload-button {
                background: none;
              }
            }
            .up-btn {
              position: absolute;
              top: 1px;
              left: 1px;
              width: 352px;
              height: 48px;
              padding-left: 15px;
              line-height: 48px;
              font-size: 14px;
              font-weight: 400;
              color: #ff6900;
              cursor: pointer;
              border-radius: 5px;
              box-sizing: border-box;
              background-color: #fff;
            }
            .clear {
              position: absolute;
              right: 15px;
              cursor: pointer;
            }
          }
        }
        &:nth-child(2) {
          margin-left: 69px;
        }
      }
    }
    .form-btns {
      @include flex-start;
      margin-top: 56px;
      button {
        @include noselect;
        width: 72px;
        height: 41px;
        border-radius: 3px;
        text-align: center;
        line-height: 41px;
        cursor: pointer;
        &.submit {
          background: #4c8cff;
          border: 1px solid #ff6900;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
        &.cancel {
          margin-left: 12px;
          background: #ffffff;
          border: 1px solid #cecece;
          font-size: 16px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
}
</style>
